<template>
     <div class="main_con">
          <div class="replacement_list">
               <!-- <div class="re_option flex_row_start_center">
                    <section class="section1 flex_row_start_center">
                         <div class="txt_title1">供应商：</div>
                         <el-select class="form_select1" v-model="categoriesVal" placeholder="">
                              <el-option v-for="(item,index) in 4" :key="index" :label="item" :value="item">
                              </el-option>
                         </el-select>
                    </section>
                    <section class="section1 flex_row_start_center">
                         <div class="txt_title1">被替代型号：</div>
                         <el-select class="form_select1" v-model="categoriesVal" placeholder="">
                              <el-option v-for="(item,index) in 4" :key="index" :label="item" :value="item">
                              </el-option>
                         </el-select>
                    </section>
                    <section class="section1 flex_row_start_center">
                         <div class="txt_title1">分类：</div>
                         <div class="txt_title1">电阻</div>
                    </section>
               </div> -->
               <div class="re_section1">
                    <div class="flex_row_between_center" style="border-bottom: 1px solid #eee">
                         <div class="title2">国产替代产品</div>
                         <div class="sld_goods_num flex_row_start_center" v-if="goodsData.page.total">
                              共<span style="margin-bottom: 2px;">{{ goodsData.page.total }}</span>件
                         </div>
                    </div>
                    <GoodsListCate3 :series="true" @chosenAttr="chosenAttr" :categoryid="categoryId1"
                         @contrast="contrast" ref="goodsCate3">
                    </GoodsListCate3>
               </div>
               <div class="re_section2">
                    <div class="sld_screen flex_row_between_center">
                         <div class="flex_row_start_center">
                              <div @click="filterSort(0)"
                                   :class="{ screen_item: true, btn_sort_default: indexNum == 0, flex_row_center_center: true }">
                                   {{ L["默认"] }}</div>
                              <div :class="{ screen_item: true, flex_row_center_center: true, sld_price_jt_down: indexNum == 4, sld_price_jt_up: indexNum == 3, btn_sort_default: indexNum == 4 || indexNum == 3 }"
                                   @click="filterSort(indexNum == 4 ? 3 : 4)">
                                   <div class="sld_price">{{ L["价格"] }}</div>
                              </div>
                              <div @click="filterSort(2)"
                                   :class="{ screen_item: true, flex_row_center_center: true, btn_sort: indexNum == 2, btn_sort_default: indexNum == 2 }">
                                   {{ "发货日" }}
                                   <i class="iconfont iconxiangshangjiantou" style="font-size: 13px"></i>
                              </div>
                              <div class="screen_item_price ">
                                   <span class="price_git">{{ L["价格"] }}</span>
                                   <div class="price_section">
                                        <div class="input_box">
                                             <input type="number" class="sld_rmb" :value="lowprice" id="lowprice"
                                                  @input="priceInput('lowprice', $event)" @keydown="keyPress" />
                                             <span class="line"></span>
                                             <input type="number" class="sld_rmb" :value="highprice" id="highprice"
                                                  @input="priceInput('highprice', $event)" @keydown="keyPress" />
                                             <span class="line1"></span>
                                        </div>

                                        <div class="ctrl flex_row_between_center">
                                             <a class="clearVal" @click="reset">{{ L["清空"] }}</a>
                                             <a class="confirm" @click="confirm">{{ L["确定"] }}</a>
                                        </div>
                                   </div>
                              </div>
                              <div class="screen_item_sel flex_row_start_center"
                                   v-if="memberInfo.memberType == 2 || memberInfo.memberType == 3">
                                   <input type="checkbox" v-model="labelCode" id="label" @change="changeBox">
                                   <label for="label">
                                        <span>有物料编码</span>
                                   </label>
                              </div>
                         </div>
                         <div class="flex-row">
                              <div class="sld_goods_num flex_row_start_center">
                                   共<span style="margin-bottom: 2px;">{{ goodsData.page.total || 0 }}</span>件
                              </div>
                              <div class="bar flex_row_center_center" style="margin-right: 10px;">
                                   <span class="el-icon-arrow-left" @click="recomChange('prev')"></span>
                                   <span class="txt21" style="font-size: 13px;">
                                        <font color="#285FDE">{{ goodsData.page.total ? goodsData.page.current : 0 }}
                                        </font> /
                                        <font>
                                             {{ Math.ceil(goodsData.page.total / 20) || 0 }}
                                        </font>
                                   </span>
                                   <span class="el-icon-arrow-right" @click="recomChange('next')"></span>
                              </div>
                         </div>

                    </div>
                    <!-- 商品列表 -->
                    <div class="sld_goods_list" v-if="goodsData.data.length">
                         <el-table ref="multipleTable" :data="goodsData.data" style="width: 100%">
                              <el-table-column label="图片" width="160" :formatter="formatter" align="center">
                                   <template #default="scope">
                                        <div class="bd12 flex_row_center_center"
                                             :style="{ backgroundImage: `url(${scope.row.goodsImage})` }"
                                             @click="toDetail(scope.row.defaultProductId)">
                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column property="goodsSpec" label="规格" width="180" :formatter="formatter" />
                              <el-table-column label="描述" width="180">
                                   <template #default="scope">
                                        <div class="bd14 flex-col">
                                             <el-scrollbar>
                                                  <div v-if="scope.row.attributeList.length" class="flex-col">
                                                       <span class="word95"
                                                            v-for="(attr, idx) in scope.row.attributeList" :key="idx">
                                                            <span>{{ attr.attributeName }}:</span>
                                                            <span style="color: #121212;">{{
                                                                 attr.attributeValue
                                                            }}</span>
                                                       </span>
                                                  </div>
                                                  <div v-else>
                                                       <span class="word96">--</span>
                                                  </div>
                                             </el-scrollbar>
                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column label="生产状态" width="140">
                                   <template #default="scope">
                                        <div class="bd15 flex-col">
                                             <span class="info54">{{
                                                  scope.row.produceState == 1 ? '现货' :
                                                       scope.row.produceState == 2 ? '半成品' : '待产'
                                             }}</span>
                                             <span class="info54">发货日：{{ scope.row.deliverTime }}天</span>
                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column label="价格" width="150">
                                   <template #default="scope">
                                        <span class="word101" v-if="scope.row.goodsPrice">¥{{
                                             scope.row.goodsPrice.toFixed(2)
                                        }}</span>
                                        <div v-else>--</div>
                                   </template>
                              </el-table-column>
                              <!-- <el-table-column label="购买数量" width="150">
                                   <template #default="scope">
                                        <div class="bd16 flex_column_center_center">
                                             <div class="section25 flex_row_center_center">
                                                  <div class="goods_edit_nem flex_row_center_center">
                                                       <span @click="editNum('minus', scope.row)">-</span>
                                                       <input type="number" v-model="scope.row.goodsNum"
                                                            @input="editNum('edit', scope.row)"
                                                            @blur="editNum('blur', scope.row)" />
                                                       <span @click="editNum('plus', scope.row)">+</span>
                                                  </div>
                                                  <span class="txt50">{{ scope.row.saleUnit }}</span>
                                             </div>
                                             <div class="section29 flex_row_start_center">
                                                  <span class="txt51">起订量{{ scope.row.minBuyNum }}</span>
                                             </div>
                                             <div class="section29 flex_row_start_center">
                                                  <span class="txt51">{{ scope.row.saleUnitDes }}</span>
                                             </div>
                                        </div>
                                   </template>
                              </el-table-column> -->
                              <el-table-column label="可替代产品" width="390">
                                   <template #default="scope">
                                        <div class="bd18 flex-col" v-if="scope.row.foreignList.length">
                                             <el-scrollbar>
                                                  <span v-for="(item, index) in scope.row.foreignList" :key="index">{{
                                                       item.name
                                                  }}
                                                  </span>
                                             </el-scrollbar>
                                        </div>
                                        <div class="bd18 flex-col" v-else>
                                             <span>--</span>
                                        </div>
                                   </template>
                              </el-table-column>
                              <!-- <el-table-column>
                                   <template #default="scope">
                                        <div class="bd17 flex_column_center_start">
                                             <button class="section30 flex_row_center_center"
                                                  @click="addCart(scope.row)"><span
                                                       class="word103">加入购物车</span></button>
                                             <div class="section31 flex-col" @click="addSampleCart(scope.row)"
                                                  v-if="memberInfo.memberType == 2 || (memberInfo.memberType == 3 && memberInfo.enterpriseMaster != 1)">
                                                  <span class="txt53">加入样品申请单</span>
                                             </div>
                                             <button class="section32 flex-col" @click="addProJ(scope.row)"
                                                  v-if="loginFlag"><span class="info57">加入项目</span></button>
                                             <button class="section32 flex-col" @click="contrastGoods(scope.row)"
                                                  v-if="scope.row.foreignList.length && hasForeignId"><span
                                                       class="info57">加入对比</span></button>
                                        </div>
                                   </template>
                              </el-table-column> -->
                         </el-table>
                         <div class="flex_row_center_center sld_pagination">
                              <el-pagination @current-change="handleCurrentChange"
                                   v-model:currentPage="goodsData.page.current" :page-size="goodsData.page.pageSize"
                                   layout="prev, pager, next, jumper" :total="goodsData.page.total"
                                   :hide-on-single-page="false" v-if="goodsData.page.total > 0">
                              </el-pagination>
                         </div>
                    </div>


                    <div class="sld_goods_list" v-show="!goodsData.data.length && !showLoading">
                         <proEmpty ref="proEmpty"></proEmpty>
                    </div>
                    <loadAnimation1 v-show="showLoading"></loadAnimation1>

               </div>
          </div>
          <!-- 对比框start -->
          <div class="contrast panelFixed" id="contrast" v-if="contrastFlag">
               <div class="wrap22 flex-col">
                    <div class="mod30 flex-col">
                         <div class="bd38 flex-row">
                              <span class="txt51">对比栏</span>
                              <span class="txt52" @click="contrastFlag = false">隐藏</span>
                         </div>
                         <div class="bd40 flex-row">
                              <div class="layer113 flex_column_center_center" v-for="(item, index) in 4" :key="index">
                                   <div class="outer22 flex_column_center_center">
                                        <div class="mod31 flex-col">
                                             <div class="box22 flex-col" v-if="contraList[index]">
                                                  <img :src="contraList[index][`${contraList[index].type}Image`]">
                                             </div>
                                             <span class="txt54" v-else>{{ index + 1 }}</span>
                                        </div>
                                        <span class="word149" v-if="contraList[index]">{{
                                             contraList[index][`${contraList[index].type}Name`]
                                        }}</span>
                                        <span class="word151" v-else>您还可以继续添加</span>
                                   </div>
                                   <span class="info70" @click="contraDel('spec', index)"
                                        v-if="contraList[index]">删除</span>
                              </div>
                              <div class="outer28 flex-col">
                                   <div class="outer29 flex-col" @click="toContrast">
                                        <span class="word152">对比</span>
                                   </div>
                                   <span class="word153" @click="contraDel('all', index)">清空对比栏</span>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <!-- 对比框end -->
          <AtoProject ref="AtoProject"></AtoProject>

          <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal"
               @refreshInfo="refreshInfo" />
     </div>
</template>
<script>
import proEmpty from "@/components/proEmpty"
import GoodsListCate3 from "@/components/GoodsListCate3";
import { getCurrentInstance, onMounted, reactive, ref, watchEffect, watch, provide } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import AtoProject from '@/components/AtoProject'
import loadAnimation1 from '@/components/loading_animation1'

export default {
     components: {
          GoodsListCate3,
          AtoProject,
          proEmpty,
          loadAnimation1
     },
     setup() {
          //变量------------------------------
          const firstLoading = ref(true); //是否第一次加载
          const skeletonData = reactive({ data: [] });
          const store = useStore();
          const memberInfo = ref(store.state.memberInfo);
          const checkList = ref('')
          const route = useRoute();
          const router = useRouter();
          const showIndex = ref(0); //显示flag
          //初始化的请求参数
          const params = reactive({
               current: 1,
          });
          const { proxy } = getCurrentInstance();
          const L = proxy.$getCurLanguage();
          const goodsData = reactive({ data: [], page: {} });
          const indexNum = ref(0);
          const lowprice = ref(null);
          const highprice = ref(null);
          const isEmpty = ref(false);
          const categoryId1 = ref(route.query.categoryId)
          const contrastFlag = ref(false)
          const contraList = ref([])
          const emitContraList = reactive({
               list: []
          })
          provide('contraList', emitContraList)
          const scrollTop = ref(null);
          const labelCode = ref(false)
          const showLoading = ref(false)
          const loginFlag = ref(store.state.loginFlag)
          const loginModalVisibleFlag = ref(false)
          const hasForeignId = ref(true)
          //方法------------------------------
          const initSkeletonData = () => {
               for (let i = 0; i < 10; i++) {
                    skeletonData.data.push({
                         goodsName: "",
                         goodsImage: "",
                         goodsPrice: "",
                         saleNum: "",
                         activityList: "",
                         isFollowGoods: "",
                         defaultProductId: "",
                    });
               }
          };
          initSkeletonData();

          //初始化数据，进行请求-start
          const getInitData = (params) => {
               window.scrollTo(0, scrollTop.value);
               showLoading.value = true
               let searchParams = { ...params };
               proxy.$get("v3/goods/front/goods/goodsList", searchParams).then((res) => {
                    firstLoading.value = false;
                    showLoading.value = false
                    if (res.state == 200) {
                         goodsData.data = res.data.list;
                         isEmpty.value = res.data.list.length ? false : true;
                         goodsData.page = res.data.pagination;
                         goodsData.data.map(item => {
                              item.goodsNum = item.minBuyNum
                         })
                    } else {
                         goodsData.data = []
                    }

                    if (!goodsData.data.length) {
                         proxy.$refs.proEmpty.getStoreList()
                    }
               });
          };




          watch(() => route.query, (nv, ov) => {

               deleteObjWhileChange()
               if (route.query.kId) {
                    params.goodsForeignId = route.query.kId;
                    hasForeignId.value = true
               } else {
                    delete params.goodsForeignId
                    hasForeignId.value = false
               }

               if (route.query.categoryId) {
                    params.categoryIds = route.query.categoryId;
                    categoryId1.value = route.query.categoryId
               } else {
                    delete params.categoryIds
               }

               params.replaceKeyword = route.query.replaceKeyword
               getInitData(params)

          })

          const deleteObjWhileChange = () => {
               delete params.highPrice;
               delete params.lowPrice;
               delete params.sort
               delete params.attributeInfo
               delete params.characterInfo
               delete params.resistanceIds
               delete params.accuracyIds
               labelCode.value = false;
               lowprice.value = highprice.value = null;
               params.current = 1
          }

          const recomChange = (type) => {
               if (type == 'next') {
                    if (params.current == Math.ceil(goodsData.page.total / 20)) {
                         return
                    }
                    params.current++
                    getInitData(params)
               } else {
                    if (params.current == 1) {
                         return
                    }
                    params.current--
                    getInitData(params)
               }
          }


          //筛选属性
          const chosenAttr = (commonList, resistorList, accurayList) => {
               if (commonList.length) {
                    const sorted = groupBy(commonList, (item) => [item.attrId]);
                    let attrList = sorted.map(item => {
                         let list = item.map(i => i.attrValId).join('-')
                         return list
                    })
                    params.attributeInfo = attrList.join(',')
               } else {
                    delete params.attributeInfo
               }

               if (accurayList.length) {
                    let list = accurayList.map(i => i.attrValId).join(',')
                    params.accuracyIds = list
               } else {
                    delete params.accuracyIds
               }

               if (resistorList.length) {
                    let list = resistorList.map(i => i.attrValId).join(',')
                    params.resistanceIds = list
               } else {
                    delete params.resistanceIds
               }
               params.current = 1
               getInitData(params);
          }

          // 删除或清空对比
          const contraDel = (type, index) => {
               if (type == 'spec') {
                    proxy.$refs.goodsCate3.srcDisFls([contraList.value[index]])//当系列的对比时的，对比框删除一项或者全部，系列列表的条目的check置为FALSE
                    contraList.value.splice(index, 1)
               } else {
                    proxy.$refs.goodsCate3.srcDisFls(contraList.value)
                    contraList.value = []
               }
               emitContraList.list = contraList.value

          }


          const editNum = (type, scopeItem) => {
               switch (type) {
                    case 'minus': {
                         if (scopeItem.goodsNum <= scopeItem.minBuyNum) {
                              return
                         } else {
                              scopeItem.goodsNum--
                         }
                         break
                    }
                    case 'edit': {
                         if ((!/^[1-9]\d*$/.test(scopeItem.goodsNum) && scopeItem.goodsNum)) {
                              scopeItem.goodsNum = scopeItem.minBuyNum
                         }

                         if (scopeItem.goodsNum > 99999) {
                              scopeItem.goodsNum = 99999
                         }

                         break
                    }
                    case 'plus': {
                         if (scopeItem.goodsNum > 99999) {
                              scopeItem.goodsNum = 99999
                         } else {
                              scopeItem.goodsNum++

                         }
                         break;
                    }

                    case 'blur': {
                         if (scopeItem.goodsNum <= scopeItem.minBuyNum || !scopeItem.goodsNum) {
                              scopeItem.goodsNum = scopeItem.minBuyNum
                         }
                         break;
                    }
               }
          }

          //筛选分组
          const groupBy = (arr, func) => {
               const groups = {};
               arr.forEach((o) => { //注意这里必须是forEach 大写
                    const group = JSON.stringify(func(o));
                    groups[group] = groups[group] || [];
                    groups[group].push(o);
               });
               return Object.keys(groups).map(function (group) {
                    return groups[group];
               });
          }


          //页数改变的方法-start
          const handlePrevCilickChange = (e) => {
               params.current = Math.floor(e);
               getInitData(params);
          };
          const handleNextCilickChange = (e) => {
               params.current = Math.floor(e);
               getInitData(params);
          };
          const handleCurrentChange = (e) => {
               params.current = Math.floor(e);
               getInitData(params);
          };
          const filterSort = (index) => {
               if (indexNum.value == index) {
                    return;
               }

               indexNum.value = index;
               params.sort = indexNum.value
               params.current = 1;
               scrollTop.value = document.body.scrollTop || document.documentElement.scrollTop;

               getInitData(params)

               proxy.$refs.goodsCate3.selectorParam.sort = indexNum.value
               proxy.$refs.goodsCate3.getInitData()
               proxy.$refs.goodsCate3.getSeries({ categoryId: route.query.categoryId })

          };

          const changeBox = (e) => {
               params.current = 1;
               scrollTop.value = document.body.scrollTop || document.documentElement.scrollTop;
               params.isHasCode = labelCode.value
               proxy.$refs.goodsCate3.selectorParam.isHasCode = labelCode.value
               proxy.$refs.goodsCate3.getInitData()
               if (!goodsData.data.length) {
                    proxy.$refs.goodsCate3.clearAttr()
               }
               proxy.$refs.goodsCate3.getSeries({ categoryId: route.query.categoryId })
               getInitData(params)
          }
          //页数改变的方法-end




          //价格区间事件-start
          const confirm = () => {
               //价格区间确认按钮

               if (lowprice.value && highprice.value) {
                    lowprice.value = Math.abs(lowprice.value);
                    highprice.value = Math.abs(highprice.value);

                    if (Number(lowprice.value) > Number(highprice.value)) {
                         let temp = lowprice.value;
                         lowprice.value = highprice.value;
                         highprice.value = temp;
                         params.highPrice = highprice.value;
                         params.lowPrice = lowprice.value;
                         params.current = 1;

                         getInitData(params);
                    } else {
                         params.highPrice =
                              Number(highprice.value) > 999999 ? 999999 : Number(highprice.value);
                         params.lowPrice = Number(lowprice.value) < 0.01 ? 0.01 : lowprice.value;
                         params.current = 1;
                         getInitData(params);
                    }
               } else if (lowprice.value && !highprice.value) {
                    lowprice.value = Math.abs(lowprice.value);
                    if (
                         lowprice.value.toString().split(".")[1] &&
                         lowprice.value.toString().split(".")[1].length > 2
                    ) {
                         lowprice.value = Number(lowprice.value).toFixed(2);
                    }
                    params.lowPrice = Number(lowprice.value) < 0.01 ? 0.01 : lowprice.value;
                    delete params.highPrice;
                    getInitData(params);

               } else if (!lowprice.value && highprice.value) {
                    highprice.value = Math.abs(highprice.value);
                    if (
                         highprice.value.toString().split(".")[1] &&
                         highprice.value.toString().split(".")[1].length > 2
                    ) {
                         highprice.value = Number(highprice.value).toFixed(2);
                    }
                    params.highPrice =
                         Number(highprice.value) > 999999 ? 999999 : Number(highprice.value);
                    delete params.lowPrice;
                    getInitData(params);
               }

               if (lowprice.value || highprice.value) {
                    refreshAttrList()
                    refreshSeriesList()
               }
          };
          const reset = () => {
               //价格区间清空按钮
               params.highPrice = highprice.value = null;
               params.lowPrice = lowprice.value = null;
               params.current = 1;

               refreshAttrList()
               refreshSeriesList()
               getInitData(params);
          };

          const refreshSeriesList = () => {
               let refObj = proxy.$refs.goodsCate3
               refObj.selectorParam.isHasCode = labelCode.value
               refObj.selectorParam.highPrice = highprice.value
               refObj.selectorParam.lowPrice = lowprice.value
               refObj.selectorParam.sort = indexNum.value
               refObj.getSeries({ categoryId: route.query.categoryId })
          }

          const refreshAttrList = () => {
               let refObj = proxy.$refs.goodsCate3
               refObj.selectorParam.isHasCode = labelCode.value
               refObj.selectorParam.highPrice = highprice.value
               refObj.selectorParam.lowPrice = lowprice.value
               refObj.selectorParam.sort = indexNum.value
               if (!goodsData.data.length) {
                    refObj.clearAttr()
               } else {
                    refObj.getInitData()
               }
          }

          const priceInput = (type, e) => {
               if (type == 'lowprice') {
                    lowprice.value = String(e.target.value).slice(0, 8)
                    let low = String(e.target.value).split('.')
                    if (low[1] && low[1].length >= 2) lowprice.value = Number(e.target.value).toFixed(2)

               }

               if (type == 'highprice') {
                    highprice.value = String(e.target.value).slice(0, 8)
                    let high = String(e.target.value).split('.')
                    if (high[1] && high[1].length >= 2) highprice.value = Number(e.target.value).toFixed(2)

               }
          }
          //价格区间事件-end

          //加入对比
          const contrast = (item) => {

               if (contraList.value.length < 4) {
                    let index = contraList.value.findIndex(i => i[`${i.type}Id`] == item[`${item.type}Id`])
                    if (index < 0) {
                         contraList.value.push(item)
                    } else {
                         ElMessage(`该系列已加入对比`)
                    }

                    if (item.type == 'series' && !item.checked) contraDel('spec', index)
               } else {
                    ElMessage('对比已达到4个')
               }

               emitContraList.list = contraList.value
               if (!contrastFlag.value) {
                    contrastFlag.value = true
               }
          }



          const contrastGoods = (item) => {
               let newWin = router.resolve({
                    path: '/goods/Contrast',
                    query: {
                         ids: item.goodsId,
                         foreignId: route.query.kId,
                         type: 'goods'
                    }
               })

               window.open(newWin.href, '_blank')
          }


          //计入我的项目
          const addProJ = (item) => {
               proxy.$refs.AtoProject.proInfo.productIds = `${item.defaultProductId}-${item.goodsNum}`
               proxy.$refs.AtoProject.visibleFlag = true
          }



          const toDetail = (productId) => {
               let newWin = router.resolve({
                    path: '/goods/detail',
                    query: {
                         productId,
                    }
               })
               window.open(newWin.href, '_blank')
          }

          //加入样品申请单
          const addSampleCart = (scopeItem) => {

               if (!scopeItem.goodsNum) {
                    ElMessage('请选择数量')
                    return
               }


               let paramsData = {
                    productId: scopeItem.defaultProductId,
                    number: scopeItem.goodsNum,
               };
               
               proxy.$addSampleCart(paramsData);

          };

          //加入购物车
          const addCart = (scopeItem) => {

               if (!scopeItem.goodsNum) {
                    ElMessage('请选择数量')
                    return
               }

               if (store.state.loginFlag) {
                    let paramsData = {
                         productId: scopeItem.defaultProductId,
                         number: scopeItem.goodsNum,
                    };
                    proxy.$addCart(paramsData);
               } else {
                    loginModalVisibleFlag.value = true
               }



          };

          const toContrast = () => {

               if (!contraList.value.length) {
                    ElMessage('请添加对比条目')
                    return
               }
               let list = contraList.value.map(i => i.seriesId)
               let newWin = router.resolve({
                    path: '/goods/Contrast',
                    query: {
                         ids: encodeURIComponent(list.join(',')),
                         type: contraList.value[0].type
                    }
               })

               window.open(newWin.href, '_blank')
          }
          //监听事件--end

          router.beforeEach((to, from) => {
               if (to.path == from.path) {
                    document.documentElement.scrollTop = scrollTop.value;
               }
          });


          const refreshInfo = () => {
               history.go(0)
          }

          //关闭登录弹框
          const closeLoingModal = () => {
               loginModalVisibleFlag.value = false;
          };


          const keyPress = (e) => {
               let prohibitInput = ["-", "e", "+", "E"];
               if (prohibitInput.includes(e.key)) {
                    e.preventDefault();
               }
          }



          onMounted(() => {

               if (route.query.kId) {
                    params.goodsForeignId = route.query.kId;
                    hasForeignId.value = true
               } else {
                    hasForeignId.value = false
               }

               if (route.query.sort) {
                    params.sort = route.query.sort;
                    indexNum.value = route.query.sort;
               }

               if (route.query.replaceKeyword) {
                    params.replaceKeyword = route.query.replaceKeyword
               }

               if (route.query.code != undefined) {
                    params.isHasCode = route.query.code;
                    labelCode.value = route.query.code;
               }

               if (route.query.categoryId) {
                    params.categoryIds = route.query.categoryId
               }

               getInitData(params);

          })
          //返回值
          return {
               memberInfo,
               goodsData,
               handlePrevCilickChange,
               handleNextCilickChange,
               handleCurrentChange,
               L,
               indexNum,
               filterSort,
               lowprice,
               highprice,
               confirm,
               reset,
               isEmpty,
               firstLoading,
               skeletonData,
               checkList,
               contrast,
               addProJ,
               chosenAttr,
               contrastFlag,
               contraList,
               contraDel,
               toDetail,
               editNum,
               addSampleCart,
               addCart,
               labelCode,
               changeBox,
               toContrast,
               categoryId1,
               contrastGoods,
               recomChange,
               params,
               showLoading,
               loginFlag,
               refreshInfo,
               closeLoingModal,
               loginModalVisibleFlag,
               priceInput,
               keyPress,
               hasForeignId
          };
     },
     //
}
</script>

<style lang="scss">
.re_option {
     .el-select .el-input {
          width: 274px;
     }
}

.bar {
     margin-left: 10px;

     .el-icon-arrow-left,
     .el-icon-arrow-right {
          color: #333;
          font-weight: bold;
          font-size: 12px;
          cursor: pointer;
          margin-top: 1px;

          &:hover {
               color: #285FDE;
          }
     }
}

/*添加css样式*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
}

.el-table__empty-block {
     display: none;
}

.sld_goods_num {
     line-height: 36px;
     margin-right: 10px;
     color: #121212;

     span {
          color: $colorMain;
          font-weight: bold;
          margin: 0 2px;
          font-size: 20px;
          font-family: SourceHanSansCN-Regular;
          line-height: 0;
     }
}

.main_con {
     background: #fff;
     padding-top: 15px;
     min-height: 600px;

     .replacement_list {
          width: 1200px;
          margin: 0 auto;
          padding-bottom: 40px;

          .re_option {
               background-color: #fff;
               padding: 7px 20px;

               .section1 {
                    margin-right: 100px;

                    .txt_title1 {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                         font-weight: 500;
                         color: #333333;
                         margin-right: 5px;
                    }
               }

          }

          .re_section1 {
               background-color: #fff;

               .title2 {
                    height: 46px;
                    line-height: 46px;
                    padding-left: 25px;
                    font-size: 18px;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-weight: 600;
                    color: #333333;
               }
          }

          .re_section2 {


               .sld_screen {
                    -moz-user-select: none;
                    /*火狐*/
                    -webkit-user-select: none;
                    /*webkit浏览器*/
                    -ms-user-select: none;
                    /*IE10*/
                    user-select: none;
                    /* padding-right: 20px; */
                    line-height: 36px;
                    position: relative;
                    height: 38px;
                    background: #F2F5FD;
                    box-sizing: border-box;

                    .screen_item {
                         width: 75px;
                         height: 38px;
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #333333;
                         border-right: 1px solid #f4f4f4;
                         cursor: pointer;

                         &.btn_sort_default {
                              background-color: $colorMain;
                              color: #fff;
                         }

                         &.btn_sort {
                              i {
                                   color: #fff;
                              }
                         }

                         .arrow {
                              transform: rotate(180deg);
                         }

                         .sld_price {
                              position: relative;
                              margin-right: 10px;

                              &::after,
                              &::before {
                                   position: absolute;
                                   content: "";
                                   right: -12px;
                                   width: 0;
                                   height: 0;
                                   border-width: 4px;
                                   border-style: solid;
                              }

                              &::before {
                                   top: 50%;
                                   transform: translateY(3px);
                                   border-color: #afafaf transparent transparent transparent;
                              }

                              &::after {
                                   top: 50%;
                                   -webkit-transform: translateY(-12px);
                                   -moz-transform: translateY(-12px);
                                   -ms-transform: translateY(-12px);
                                   -o-transform: translateY(-12px);
                                   transform: translateY(-9px);
                                   border-color: transparent transparent #afafaf transparent;
                              }
                         }
                    }

                    .screen_item_price {
                         height: 38px;
                         padding: 0 12px;
                         border-right: 1px solid #f4f4f4;
                         display: flex;

                         .price_git {
                              display: inline-block;
                              line-height: 38px;
                         }
                    }

                    .screen_item_sel {
                         padding: 0 14px;
                         border-right: 1px solid #f4f4f4;
                         height: 38px;
                         cursor: pointer;

                         .sel_icon {
                              width: 13px;
                              height: 13px;
                              border: 1px solid #666666;
                              margin-right: 10px;

                              .icon-finish {
                                   font-size: 12px;
                                   transform: scale(0.8);
                              }
                         }

                         label,
                         input {
                              cursor: pointer;
                         }

                         #label {
                              margin-right: 10px;

                              &:checked+label {
                                   span {
                                        color: $colorMain;
                                   }
                              }


                         }
                    }

                    .good_type {
                         p a input {
                              vertical-align: middle;
                              margin-top: 3px;
                         }
                    }

                    &>a.btn_sort {
                         color: $colorMain2;
                    }

                    i {
                         color: #afafaf;
                    }

                    &>a {
                         margin: 0 20px 0 15px;

                         &:hover {
                              color: $colorMain;

                              i {
                                   color: $colorMain;
                              }
                         }
                    }

                    &>a,
                    &>input,
                    &>div {
                         line-height: 26px;
                    }

                    .sld_goods_num {
                         line-height: 36px;
                         margin-right: 10px;

                         span {
                              color: $colorMain;
                              font-weight: bold;
                              margin: 0 2px;
                              font-size: 20px;
                              font-family: SourceHanSansCN-Regular;
                              line-height: 0;
                         }
                    }

                    .sld_price_jt_down {
                         position: relative;

                         .sld_price {
                              &::before {
                                   border-color: #fff transparent transparent transparent;
                              }

                              &::after {
                                   border-color: transparent transparent #afafaf transparent;
                              }
                         }
                    }

                    .sld_price_jt_up {
                         position: relative;

                         .sld_price {
                              &::before {
                                   border-color: #afafaf transparent transparent transparent;
                              }

                              &::after {
                                   border-color: transparent transparent #fff transparent;
                              }
                         }


                    }

                    .goods_page {
                         line-height: 26px;

                         a,
                         p {
                              display: inline-block;

                              em {
                                   color: $colorMain2;
                              }
                         }

                         a {
                              padding: 0 6px;

                              i {
                                   font-size: 12px;
                                   color: #666666;
                              }

                              i.prev {
                                   display: inline-block;
                                   -webkit-transform: rotate(90deg);
                                   -moz-transform: rotate(90deg);
                                   -ms-transform: rotate(90deg);
                                   -o-transform: rotate(90deg);
                                   transform: rotate(90deg);
                              }

                              i.next {
                                   display: inline-block;
                                   -webkit-transform: rotate(-90deg);
                                   -moz-transform: rotate(-90deg);
                                   -ms-transform: rotate(-90deg);
                                   -o-transform: rotate(-90deg);
                                   transform: rotate(-90deg);
                              }
                         }
                    }
               }

               .sld_goods_list {
                    margin: 15px 0;

                    .bd12 {
                         width: 106px;
                         height: 106px;
                         padding-left: 30px;
                         background-position: center;
                         background-repeat: no-repeat;
                         background-size: contain;
                         cursor: pointer;
                    }

                    .bd14 {
                         height: 120px;

                         .word95,
                         .word96 {
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                              font-weight: 400;
                              line-height: 20px;
                         }

                         .word96 {
                              line-height: 120px;
                         }
                    }

                    .bd15 {
                         .info54 {
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                              font-weight: 400;
                              color: #686868;
                              line-height: 20px;
                         }
                    }

                    .word101 {

                         display: block;
                         color: rgba(243, 2, 19, 1);
                         font-size: 12px;
                         text-align: left;
                    }

                    .bd16 {

                         .goods_edit_nem {

                              background: #ffffff;
                              border: 1px solid #dddddd;

                              span {
                                   width: 26px;
                                   height: 30px;
                                   background: #fff;
                                   text-align: center;
                                   display: block;
                                   line-height: 30px;
                                   cursor: pointer;
                              }

                              input {
                                   width: 44px;
                                   height: 30px;
                                   border: none;
                                   border-left: 1px solid #dddddd;
                                   border-right: 1px solid #dddddd;
                                   text-align: center;
                                   line-height: 30px;
                              }

                              /*** 消除input元素 type="number" 时默认的 加减按钮*/
                              input[type="number"]::-webkit-inner-spin-button,
                              input[type="number"]::-webkit-outer-spin-button {
                                   -webkit-appearance: none;
                                   margin: 0;
                              }

                              /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
                              input[type="number"] {
                                   -moz-appearance: textfield;
                              }
                         }

                         .section25 {
                              justify-content: space-between;



                              .txt50 {
                                   width: 12px;
                                   height: 12px;
                                   display: block;
                                   overflow-wrap: break-word;
                                   color: rgba(68, 68, 68, 1);
                                   font-size: 12px;
                                   font-family: SourceHanSansCN-Regular;
                                   white-space: nowrap;
                                   line-height: 12px;
                                   text-align: left;
                                   margin-top: 7px;
                                   margin-left: 5px;
                              }
                         }

                         .section29 {
                              height: 12px;
                              margin-top: 4px;
                              justify-content: space-between;

                              .txt51 {
                                   width: 50px;
                                   height: 12px;
                                   display: block;
                                   overflow-wrap: break-word;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 12px;
                                   font-family: SourceHanSansCN-Regular;
                                   white-space: nowrap;
                                   line-height: 12px;
                                   text-align: left;
                              }

                              .txt52 {
                                   width: 60px;
                                   height: 12px;
                                   display: block;
                                   overflow-wrap: break-word;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 12px;
                                   font-family: SourceHanSansCN-Regular;
                                   white-space: nowrap;
                                   line-height: 12px;
                                   text-align: left;
                              }
                         }
                    }

                    .bd17 {


                         .section30 {
                              height: 28px;
                              background: $colorMain;
                              width: 95px;
                              cursor: pointer;
                              border: none;
                              outline: none;

                              .word103 {
                                   width: 60px;
                                   display: block;
                                   overflow-wrap: break-word;
                                   color: rgba(255, 255, 255, 1);
                                   font-size: 12px;
                                   font-family: SourceHanSansCN-Regular;
                                   white-space: nowrap;
                                   line-height: 18px;
                                   text-align: left;
                              }
                         }

                         .section31 {
                              height: 28px;
                              border: 1px solid rgba(226, 226, 226, 1);
                              background: #fff;
                              align-self: flex-start;
                              margin-top: 6px;
                              width: 97px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              cursor: pointer;

                              .txt53 {
                                   width: 84px;
                                   height: 12px;
                                   display: block;
                                   overflow-wrap: break-word;
                                   color: rgba(51, 51, 51, 0.79);
                                   font-size: 12px;
                                   font-family: SourceHanSansCN-Regular;
                                   white-space: nowrap;
                                   line-height: 12px;
                              }
                         }

                         .section32 {
                              height: 28px;
                              border: 1px solid rgba(226, 226, 226, 1);
                              background: #fff;
                              align-self: flex-start;
                              margin-top: 3px;
                              width: 95px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              cursor: pointer;

                              .info57 {
                                   width: 48px;
                                   height: 12px;
                                   display: block;
                                   overflow-wrap: break-word;
                                   color: rgba(51, 51, 51, 0.79);
                                   font-size: 12px;
                                   font-family: SourceHanSansCN-Regular;
                                   white-space: nowrap;
                                   line-height: 12px;
                              }
                         }
                    }

                    .bd18 {
                         height: 120px;
                         padding-left: 10px;
                         word-break: break-all;
                    }
               }

               .price_section {
                    border: 1px solid transparent;
                    margin-left: 10px;
                    padding: 4px;
                    position: relative;

                    .ctrl {
                         display: none;
                         padding-top: 8px;
                         position: absolute;
                         width: 162px;

                         a {
                              height: 23px;
                              padding: 0 10px;

                              &:nth-child(1) {
                                   color: #005aa0;
                              }

                              &:nth-child(2) {
                                   color: #333333;
                                   line-height: 21px;
                                   border: 1px solid #cccccc;
                              }

                              cursor: pointer;
                         }


                    }

                    .input_box {
                         position: relative;
                         margin-top: 1px;

                         input[type="number"] {
                              position: relative;
                              width: 71px;
                              height: 26px;
                              line-height: 24px;
                              border: 1px solid #e5e5e5;
                              padding-left: 15px;
                              box-sizing: border-box;
                              outline: none;
                         }

                         .line {
                              display: inline-block;
                              width: 12px;
                              height: 2px;
                              background-color: #a9a9a9;
                              margin: 0 6px;
                              vertical-align: middle;

                              &::after {
                                   top: 1px;
                                   left: 88px;
                                   position: absolute;
                                   top: 0;
                                   left: 5px;
                                   content: "￥";
                                   font-size: 12px;
                                   color: #a9a9a9;
                                   line-height: 24px;
                                   z-index: 0;
                              }
                         }

                         .line1 {
                              &::after {
                                   top: 1px;

                                   position: absolute;
                                   top: 0;
                                   right: 54px;
                                   content: "￥";
                                   font-size: 12px;
                                   color: #a9a9a9;
                                   line-height: 24px;
                                   z-index: 0;
                              }
                         }
                    }

                    &:hover {
                         height: 78px;
                         border-color: #999;
                         background-color: #fff;
                         z-index: 99;
                    }

                    &:hover .ctrl {
                         display: flex;
                    }
               }

               .block13 {
                    z-index: auto;
                    width: 874px;
                    height: 13px;
                    margin: 11px 0 0 61px;
                    padding: 15px 0;

                    .word89 {
                         width: 24px;
                         height: 12px;
                         display: block;
                         overflow-wrap: break-word;
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 12px;
                         text-align: left;
                         margin-top: 1px;
                    }

                    .word90 {
                         width: 48px;
                         height: 12px;
                         display: block;
                         overflow-wrap: break-word;
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 12px;
                         text-align: left;
                         margin-left: 99px;
                    }

                    .word91 {
                         width: 24px;
                         height: 12px;
                         display: block;
                         overflow-wrap: break-word;
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 12px;
                         text-align: left;
                         margin-left: 250px;
                    }

                    .info53 {
                         width: 48px;
                         height: 12px;
                         display: block;
                         overflow-wrap: break-word;
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 12px;
                         text-align: left;
                         margin-left: 167px;
                    }

                    .word92 {
                         width: 24px;
                         height: 12px;
                         display: block;
                         overflow-wrap: break-word;
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 12px;
                         text-align: left;
                         margin-left: 85px;
                    }

                    .word93 {
                         width: 48px;
                         height: 12px;
                         display: block;
                         overflow-wrap: break-word;
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 12px;
                         text-align: left;
                         margin-left: 57px;
                    }
               }

               .group11 {
                    background-color: #fff;
                    margin-top: 20px;
                    justify-content: flex-start;
                    padding: 15px;
                    position: relative;

                    .mod13 {
                         background-color: #f8f8f8;
                    }

                    .section13 {
                         border-bottom: 1px solid #d8d8d8;
                         padding: 15px 0;
                         justify-content: flex-start;
                    }

                    .icon8 {
                         width: 16px;
                         height: 16px;
                         color: $colorMain;
                         margin-right: 10px;
                         margin-left: 20px;
                    }

                    .word49 {
                         width: 448px;
                         height: 14px;
                         display: block;
                         overflow-wrap: break-word;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 14px;
                         text-align: left;
                         margin-top: 1px;
                    }

                    .section15 {
                         width: 103px;
                         height: 91px;
                         margin-top: 43px;
                         margin-bottom: 20px;
                    }

                    .word50 {
                         width: 114px;
                         height: 16px;
                         display: block;
                         overflow-wrap: break-word;
                         color: $colorMain;
                         font-size: 16px;
                         text-decoration: underline;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 16px;
                         margin-bottom: 20px;
                    }

                    .mod14 {
                         height: 56px;

                         width: 56px;
                         justify-content: center;
                         align-items: center;
                         position: absolute;
                         left: 580px;
                         top: 105px;
                    }

                    .label13 {
                         width: 23px;
                         height: 20px;
                    }
               }



          }

     }

     .panelFixed {
          width: 100%;
          position: fixed;
          bottom: 0;
          left: 0;
     }

     .contrast_panel {
          height: 290px;
     }

     .contrast {
          // position: absolute;
          z-index: 999;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;

          .wrap22 {
               height: 288px;
               background: #fff;
               width: 1200px;
               border: 2px solid #cccccc;

               .mod30 {
                    .bd38 {
                         padding: 0 20px;
                         align-items: center;
                         justify-content: space-between;
                         height: 54px;
                         border-bottom: 1px solid #cccccc;

                         .txt51 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 16px;
                         }

                         .txt52 {
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                              cursor: pointer;
                         }
                    }

                    .bd40 {
                         height: 228px;

                         .layer113 {
                              width: 240px;
                              position: relative;
                              border-right: 1px dashed #999999;

                              .info70 {
                                   position: absolute;
                                   color: rgba(40, 95, 222, 1);
                                   font-size: 14px;
                                   top: 13px;
                                   right: 13px;
                                   cursor: pointer;
                              }

                              .outer22 {
                                   width: 150px;
                                   height: 179px;
                                   margin-top: 20px;

                                   .mod31 {
                                        position: relative;
                                        width: 150px;
                                        height: 150px;
                                        overflow: hidden;
                                        background: #f7f7f7f7;

                                        .box22 {
                                             position: absolute;
                                             left: 7px;
                                             top: 14px;
                                             width: 137px;
                                             height: 125px;
                                             background: #f7f7f7f7;

                                             img {
                                                  width: 137px;
                                                  height: 125px;
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }

               .word149 {
                    width: 220px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    margin-top: 15px;
               }

               .txt54 {
                    position: absolute;
                    left: 54px;
                    top: 31px;
                    color: rgba(204, 204, 204, 1);
                    font-size: 74px;
               }

               .word151 {
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                    margin-top: 15px;
               }

               .outer28 {
                    width: 90px;
                    height: 78px;
                    margin: 69px 0 0 74px;
               }

               .outer29 {
                    height: 34px;
                    background: $colorMain;
                    width: 90px;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
               }

               .word152 {
                    color: rgba(255, 255, 255, 1);
                    font-size: 14px;
               }

               .word153 {
                    width: 90px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    margin-top: 30px;
                    text-align: center;
                    cursor: pointer;
               }

               .mod32 {
                    position: absolute;
                    left: 240px;
                    top: 60px;
                    width: 1px;
                    height: 228px;
                    background: #f7f7f7f7;
               }

               .mod33 {
                    position: absolute;
                    left: 480px;
                    top: 60px;
                    width: 1px;
                    height: 228px;
                    background: #f7f7f7f7;
               }
          }
     }
}

.group11 {
     background-color: #fff;
     margin-top: 20px;
     justify-content: flex-start;
     padding: 15px;
     position: relative;

     .mod13 {
          background-color: #f8f8f8;
     }

     .section13 {
          border-bottom: 1px solid #d8d8d8;
          padding: 15px 0;
          justify-content: flex-start;
     }

     .icon8 {
          width: 16px;
          height: 16px;
          color: $colorMain;
          margin-right: 10px;
          margin-left: 20px;
     }

     .word49 {
          width: 448px;
          height: 14px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 14px;
          text-align: left;
          margin-top: 1px;
     }

     .section15 {
          width: 103px;
          height: 91px;
          margin-top: 43px;
          margin-bottom: 20px;
     }

     .word50 {
          width: 114px;
          height: 16px;
          display: block;
          overflow-wrap: break-word;
          color: $colorMain;
          font-size: 16px;
          text-decoration: underline;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          line-height: 16px;
          margin-bottom: 20px;
     }

     .mod14 {
          height: 56px;

          width: 56px;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 580px;
          top: 105px;
     }

     .label13 {
          width: 23px;
          height: 20px;
     }
}

.empty_bus {
     padding-bottom: 50px;

     .layer23 {
          display: flex;
          z-index: auto;
          width: 954px;
          height: 16px;
          justify-content: space-between;
          margin: 30px 0 0 101px;

          .word {
               height: 16px;
               display: block;
               overflow-wrap: break-word;
               color: rgba(102, 102, 102, 1);
               font-size: 16px;
               font-family: SourceHanSansCN-Regular;
               white-space: nowrap;
               line-height: 16px;
               text-align: left;
          }
     }

     .layer24 {
          z-index: auto;
          width: 938px;
          height: 16px;
          justify-content: space-between;
          margin: 40px 0 0 117px;
          display: flex;

          .word {
               height: 16px;
               display: block;
               overflow-wrap: break-word;
               color: rgba(102, 102, 102, 1);
               font-size: 16px;
               font-family: SourceHanSansCN-Regular;
               white-space: nowrap;
               line-height: 16px;
               text-align: left;
          }
     }
}
</style>